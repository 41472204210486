import React from "react";
import Layout from "../components/Layout";
import Index from "../components/member-benefits/Index";
import { Helmet } from 'react-helmet'

const memberBenifit = () => {
 
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Auto Genius - Marketing Community Member Benefits of Slack Group</title>
        <meta
          name="description"
          content="The benefits of joining automotive's leading Slack group community for marketing professionals."
        />
      </Helmet>
      <Layout>
    
      
      <Index />
    </Layout>
    </>
  );
};

export default memberBenifit;
