import React from "react";
import {useStaticQuery, graphql} from "gatsby";
const Content = ({memberbenefitData}) => {
  const dataMultiContent = useStaticQuery(graphql`
    query dataMulti {
      allSanityMemberBenifit {
        edges {
          node {
            sections {
              ... on SanitySectionMultiContent {
                _key
                _type
                multiContentBlocks {
                  title
                  description
                  mainImage {
                    asset {
                      url
                    }
                    alt
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const multiContentBlock =
    dataMultiContent.allSanityMemberBenifit.edges[0].node;

  return (
    <div className="w-full bg-white">
      <div className="md:max-w-5xl mx-auto px-8 py-8 md:py-14">

        <p className="text-black text-lg font-light pt-10 pb-16">
          {memberbenefitData.sections[0] !== null &&
          memberbenefitData.sections[0] !== undefined
            ? memberbenefitData.sections[0].description
            : null}
        </p>
      </div>

      {/* 2nd Section */}
      <div className="bg-yellow w-full pt-24">
        <div className="md:max-w-3xl mx-auto md:px-3">
          <div className="flex justify-center mb-7">
            {memberbenefitData.sections[1].mainImage && <img
              src={
                memberbenefitData.sections[1] !== null &&
                memberbenefitData.sections[1] !== undefined
                  ? memberbenefitData.sections[1].mainImage.asset.url
                  : null
              }
              alt={
                memberbenefitData.sections[1] !== null &&
                memberbenefitData.sections[1] !== undefined
                  ? memberbenefitData.sections[1].mainImage.alt
                  : "message"
              }
              className="w-16 md:w-24"
            />}
          </div>

          <h1 className="text-black px-10 text-2xl md:text-3xl font-semibold md:text-center pb-5">
            {memberbenefitData.sections[1] !== null &&
            memberbenefitData.sections[1] !== undefined
              ? memberbenefitData.sections[1].mention
              : null}
          </h1>
          <p className="text-left px-10 md:px-0 md:text-xl text-black md:text-center pb-24">
            {memberbenefitData.sections[1] !== null &&
            memberbenefitData.sections[1] !== undefined
              ? memberbenefitData.sections[1].description
              : null}
          </p>
        </div>
      </div>
      {/* 3rd Section */}
      <div className="max-w-6xl mx-auto md:px-3 py-16">
        <div className="w-full md:flex md:flex-row flex-wrap justify-between">
          {multiContentBlock && multiContentBlock.sections[4] && multiContentBlock.sections[4].multiContentBlocks && multiContentBlock.sections[4].multiContentBlocks.map(
            (item, index) => {
              return (
                <div key={index} className="md:max-w-xs mb-6">
                  <div className="w-16 h-16 md:w-24 justify-center m-auto md:w-auto md:flex mb-3">
                    {item.mainImage && <img
                      src={item.mainImage.asset.url}
                      alt={item.mainImage.alt}
                      className="w-16 h-16 md:w-24 md:h-20"
                    />}
                  </div>
                  <h1 className="text-black text-2xl md:text-xl font-semibold text-center pb-5">
                    {item.title}
                  </h1>
                  <p className="px-12 md:px-0 text-black text-center font-light">
                    {item.description}
                  </p>
                </div>
              );
            }
          )}
        </div>
      </div>
      {/* 4th Section */}
      <div className="bg-yellow w-full pt-14">
        <div className="max-w-xl mx-auto px-3">
          <div className="flex justify-center mb-7">
            {memberbenefitData.sections[2].mainImage && <img
              src={
                memberbenefitData.sections[2] !== null &&
                memberbenefitData.sections[2] !== undefined
                  ? memberbenefitData.sections[2].mainImage.asset.url
                  : null
              }
              alt={
                memberbenefitData.sections[2] !== null &&
                memberbenefitData.sections[2] !== undefined
                  ? memberbenefitData.sections[2].mainImage.alt
                  : "message"
              }
              className="w-16"
            />}
          </div>

          <h1 className="px-10 text-2xl md:text-3xl text-black font-semibold md:text-center pb-5">
            {memberbenefitData.sections[2] !== null &&
            memberbenefitData.sections[2] !== undefined
              ? memberbenefitData.sections[2].mention
              : null}
          </h1>
          <p className="px-10 md:px-0 text-base md:text-xl text-black md:text-center pb-24">
            {memberbenefitData.sections[2] !== null &&
            memberbenefitData.sections[2] !== undefined
              ? memberbenefitData.sections[2].description
              : null}
          </p>
        </div>
      </div>
      {/* 5th Section */}
      <div className="max-w-6xl mx-auto px-3 py-16">
        <div className="flex flex-row flex-wrap justify-between">
          {multiContentBlock && multiContentBlock.sections[5] && multiContentBlock.sections[5].multiContentBlocks && multiContentBlock.sections[5].multiContentBlocks.map(
            (item, index) => {
              return (
                <div key={index} className="md:max-w-xs mb-12 md:mb-4">
                  <div className="flex justify-center mb-3">
                    {item.mainImage && <img
                      src={item.mainImage.asset?.url}
                      alt={item.mainImage.alt}
                      className="w-16 mb-4"
                    />}
                  </div>
                  <h1 className="px-10 text-black text-2xl md:text-xl font-semibold md:text-center pb-5">
                    {item.title}
                  </h1>
                  <p className="px-10 md:px-4 text-black md:text-center font-light">
                    {item.description}
                  </p>
                </div>
              );
            }
          )}
        </div>
      </div>
      {/* 6th Section */}
      <div className="bg-yellow w-full py-12">
        <div className="max-w-xl mx-auto px-3">
          <div className="flex justify-center mb-7">
            {memberbenefitData.sections[3].mainImage && <img
              src={
                memberbenefitData.sections[3] !== null &&
                memberbenefitData.sections[3] !== undefined
                  ? memberbenefitData.sections[3].mainImage.asset.url
                  : null
              }
              alt={
                memberbenefitData.sections[3] !== null &&
                memberbenefitData.sections[3] !== undefined
                  ? memberbenefitData.sections[3].mainImage.alt
                  : "message"
              }
              className="w-20"
            />}
          </div>

          <h1 className="text-2xl md:text-3xl text-black font-semibold text-center pb-5">
            {memberbenefitData.sections[3] !== null &&
            memberbenefitData.sections[3] !== undefined
              ? memberbenefitData.sections[3].mention
              : null}
          </h1>
          <p className="text-base md:text-xl text-black text-center pb-16">
            {memberbenefitData.sections[3] !== null &&
            memberbenefitData.sections[3] !== undefined
              ? memberbenefitData.sections[3].description
              : null}
          </p>
        </div>
      </div>
      {/* 7th Section */}
      <div className="max-w-6xl mx-auto px-3 py-16">
        <div className="flex flex-row flex-wrap justify-between">
          {multiContentBlock && multiContentBlock.sections[6] && multiContentBlock.sections[6].multiContentBlocks && multiContentBlock.sections[6].multiContentBlocks.map(
            (item, index) => {
              return (
                <div key={index} className="md:max-w-xs mb-12">
                  <div className="flex justify-center mb-3">
                    {item.mainImage && <img
                      src={item.mainImage.asset.url}
                      alt={item.mainImage.alt}
                      className="w-12"
                    />}
                  </div>
                  <h1 className="text-black text-2xl md:text-xl font-semibold text-center pb-5">
                    {item.title}
                  </h1>
                  <p className="px-24 md:px-0 text-black text-center font-light">
                    {item.description}
                  </p>
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};

export default Content;
